import { IBinaryBlobConverter, BinaryBlob, isNullOrUndefined, SerializationException } from 'in-time-core';
import { Bytes } from '@angular/fire/firestore';
import { Buffer } from 'buffer';

export class BinaryBlobConverter implements IBinaryBlobConverter {
  toBase64(bytes: Uint8Array): string {
    return bytes instanceof Buffer ? bytes.toString('base64') : Buffer.from(bytes).toString('base64');
  }

  fromBase64(str: string): Uint8Array {
    return Buffer.from(str, 'base64');
  }

  toBinaryBlob(bytes: Uint8Array): BinaryBlob {
    return Bytes.fromUint8Array(bytes);
  }

  fromBinaryBlob(blob: BinaryBlob): Uint8Array {
    if(!isNullOrUndefined(blob)) {
      if(blob instanceof Bytes) {
        return blob.toUint8Array();
      }
      else if(blob instanceof Uint8Array) {
        return blob as Uint8Array;
      }
      else if(blob instanceof Array) {
        return new Uint8Array(blob);
      }
      else {
        throw new SerializationException('Binary blob is not an instance of Buffer');
      }
    }
    else {
      throw new SerializationException('Binary blob must not be null or undefined');
    }
  }
}
