<ng-container *transloco="let t">
  @if(isVisible()) {
    <div class="cookie-panel z-[801]">
      <p class="cookie-message">{{ t('cookiesPanel.text') }} {{ t('cookiesPanel.lookAtLink') }} <a [routerLink]="['/cookies-policy']" class="underline">{{ t('cookiesPanel.cookiesPolicy') }}</a></p>
      <div class="flex items-center justify-center">
        <button class="cookie-button" (click)="declineCookies()">{{ t('cookiesPanel.decline') }}</button>
        <button class="cookie-button accept" (click)="agreeWithCookies()">{{ t('cookiesPanel.accept') }}</button>
      </div>
    </div>
  }
</ng-container>