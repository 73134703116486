import { ErrorHandler, Provider } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './app.environment';
import { isDevelopmentProject } from './core/utils/firebase-utils';

export function isSentryAllowed(): boolean {
  return environment.production && environment.allowSentry && !isDevelopmentProject();
}

export function provideSentryErrorHandler(): Provider {
  return {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({ showDialog: false }),
  };
}
