import { Component, OnDestroy, OnInit, PLATFORM_ID, inject, signal } from '@angular/core';
import { CookieService, LayoutService } from '../../../services';
import { Subscription, combineLatest } from 'rxjs';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatIconModule,
  ],
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit, OnDestroy {
  private readonly cookieService = inject(CookieService);
  private readonly layoutService = inject(LayoutService);
  private readonly router = inject(Router);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private subscriptions: Subscription[] = [];
  public readonly showNavbar = signal(false);
  public readonly currentRoute = signal('/');
  public readonly shouldRequestCookieConsent = signal(false);

  ngOnInit(): void {
    if(!this.isBrowser) {
      return;
    }

    this.showNavbar.set(this.layoutService.state.showNavbar);

    this.subscriptions.push(this.layoutService.state$.subscribe(
      (state) => this.showNavbar.set(state.showNavbar)
    ));

    this.subscriptions.push(this.router.events.subscribe((evt) => {
      if(evt instanceof NavigationEnd) {
        if(evt.url.startsWith('/account') || evt.url.startsWith('/login')) {
          this.currentRoute.set('/account');
        }
        else if(evt.url.startsWith('/my-tickets')) {
          this.currentRoute.set('/my-tickets');
        }
        else {
          this.currentRoute.set('/');
        }
      }
    }));

    this.subscriptions.push(combineLatest([
      this.cookieService.shouldRequestCookieConsent$,
      this.layoutService.state$,
    ]).subscribe(
      ([requireConsent, layoutState]) => this.shouldRequestCookieConsent.set(requireConsent && layoutState.showCookiePanel)
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.length = 0;
  }
}