import { Component, OnDestroy, OnInit, PLATFORM_ID, inject, signal } from '@angular/core';
import { AnalyticsService, CookieService, LayoutService } from '../../../services';
import { Subscription, combineLatest } from 'rxjs';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { isPlatformBrowser } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    TranslocoModule,
  ],
  selector: 'app-cookies-panel',
  templateUrl: './cookies-panel.component.html',
  styleUrls: ['./cookies-panel.component.scss']
})
export class CookiesPanelComponent implements OnInit, OnDestroy {
  private readonly analyticsService = inject(AnalyticsService);
  private readonly cookieService = inject(CookieService);
  private readonly layoutService = inject(LayoutService);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private subscription: Subscription | null = null;

  public readonly isVisible = signal(false);

  ngOnInit(): void {
    if(this.isBrowser) {
      this.subscription = combineLatest([
        this.cookieService.shouldRequestCookieConsent$,
        this.layoutService.state$,
      ]).subscribe(
        ([requireConsent, layoutState]) => {
          this.isVisible.set(requireConsent && layoutState.showCookiePanel);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  agreeWithCookies(): void {
    this.analyticsService.onCookiesAccepted();
  }

  declineCookies(): void {
    this.analyticsService.onCookiesDeclined();
  }
}