<ng-container *transloco="let t">
  @if(showNavbar()) {
    <nav class="navbar bg-white rounded-t-2xl z-[800] fixed bottom-0 w-full h-navbar flex items-center justify-center gap-x-16" [ngClass]="{ 'with-cookies': shouldRequestCookieConsent() }">
      <a class="nav-item" routerLink="/" [ngClass]="{ active: currentRoute() === '/' }">
        <mat-icon svgIcon="home-outline"></mat-icon>
        <span class="text-xs">{{ t('navbar.home') }}</span>
      </a>
      <a class="nav-item" routerLink="/my-tickets" [ngClass]="{ active: currentRoute() === '/my-tickets' }">
        <mat-icon svgIcon="ticket"></mat-icon>
        <span class="text-xs">{{ t('navbar.myTickets') }}</span>
      </a>
      <a class="nav-item" routerLink="/account" [ngClass]="{ active: currentRoute() === '/account' }">
        <mat-icon svgIcon="account-circle-outline"></mat-icon>
        <span class="text-xs">{{ t('navbar.account') }}</span>
      </a>
    </nav>
  }
</ng-container>