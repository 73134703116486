/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/en';
import 'dayjs/locale/ro';
import 'dayjs/locale/hu';

import { TimeZone } from 'in-time-core';
import { initializeSerialization } from './app.serialization';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { ConsoleLogger, LogLevel, Logger, initialize, logInfo } from 'in-time-logger';
import { SentryLogger } from './core/utils/sentry-logger';

import {
  AccountService,
  AuthenticationService,
  BusinessSubscriptionService,
  CloudSaveService,
  CookieService,
  GeoLocationService,
  LanguageService,
  MenuFavoritesService,
  UserPrefsService
} from './services';

import { isSentryAllowed } from './app.sentry';
import { isDevelopmentProject } from './core/utils/firebase-utils';
import { createServerDatabaseIdGenerator } from './core/utils/crypto-utils.server';
import { createBrowserDatabaseIdGenerator } from './core/utils/crypto-utils';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export function provideServerAppInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initServerApp,
    deps: [
      LanguageService,
      GeoLocationService,
      CookieService,
    ],
  };
}

export function provideBrowserAppInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initBrowserApp,
    deps: [
      LanguageService,
      GeoLocationService,
      AuthenticationService,
      MenuFavoritesService,
      CookieService,
      AccountService,
      CloudSaveService,
      UserPrefsService,
      BusinessSubscriptionService,
    ]
  };
}

function initServerApp(
  languageService: LanguageService,
  geoLocationService: GeoLocationService,
  cookieService: CookieService,
): () => Promise<void> {
  return async (): Promise<void> => {
    const loggers: Logger[] = [
      new ConsoleLogger(isDevelopmentProject() ? LogLevel.Debug : LogLevel.Info, 'in-time-web')
    ];

    if(isSentryAllowed()) {
      loggers.push(new SentryLogger());
    }

    initialize(loggers);

    logInfo('App is initializing...');

    TimeZone.ensureInitialized();
    initializeSerialization({
      databaseIdGenerator: createServerDatabaseIdGenerator(),
    });

    cookieService.load();

    geoLocationService.load({ canDoIpCheck: false });
    languageService.load();

    logInfo('App has been initialized!');
  };
}

function initBrowserApp(
  languageService: LanguageService,
  geoLocationService: GeoLocationService,
  authenticationService: AuthenticationService,
  menuFavoritesService: MenuFavoritesService,
  cookieService: CookieService,
  accountService: AccountService,
  cloudSaveService: CloudSaveService,
  userPrefsService: UserPrefsService,
  businessSubscriptionService: BusinessSubscriptionService,
): () => Promise<void> {
  return async (): Promise<void> => {
    const loggers: Logger[] = [
      new ConsoleLogger(isDevelopmentProject() ? LogLevel.Debug : LogLevel.Info, 'in-time-web')
    ];

    if(isSentryAllowed()) {
      loggers.push(new SentryLogger());
    }

    initialize(loggers);

    logInfo('App is initializing...');

    TimeZone.ensureInitialized();
    initializeSerialization({
      databaseIdGenerator: createBrowserDatabaseIdGenerator(),
    });

    cookieService.load();

    geoLocationService.load({ canDoIpCheck: true });
    languageService.load();
    menuFavoritesService.load();

    logInfo('App has been initialized!');
  };
}
