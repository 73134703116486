import { ApplicationConfig, EnvironmentProviders, Injectable, Provider, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';

import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideBrowserAppInitializer } from './app.initializer';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from './app.environment';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { TranslocoHttpLoader } from './core/utils/transloco-loader';
import { provideTransloco, provideTranslocoMissingHandler, TranslocoConfig, TranslocoMissingHandler } from '@ngneat/transloco';
import { isSentryAllowed, provideSentryErrorHandler } from './app.sentry';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MetaModule } from './meta/meta.module';
import { getActiveAppFlavor } from './app.flavors';

@Injectable()
export class DefaultMissingHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    if(config.missingHandler.logMissingKey && !config.prodMode) {
      const msg = `Missing translation for '${key}'`;
      console.warn(`%c ${msg}`, 'font-size: 12px; color: red');
    }

    return null;
  }
}

function getAppProviders(): (Provider | EnvironmentProviders)[] {
  const providers: (Provider | EnvironmentProviders)[] = [];
  const flavor = getActiveAppFlavor();

  if(isSentryAllowed()) {
    providers.push(provideSentryErrorHandler());
  }

  providers.push(provideAnimationsAsync());

  providers.push(provideRouter(
    flavor.routes,
    withEnabledBlockingInitialNavigation(),
    withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
  ));

  providers.push(provideClientHydration());
  providers.push(provideHttpClient(withFetch()));

  providers.push(provideTransloco({
    config: {
      availableLangs: ['en', 'hu', 'ro'],
      defaultLang: environment.defaultLanguage,
      reRenderOnLangChange: environment.canChangeLanguage,
      prodMode: !isDevMode(),
    },
    loader: TranslocoHttpLoader,
  }));

  providers.push(provideTranslocoMissingHandler(DefaultMissingHandler));

  providers.push(importProvidersFrom(NgxWebstorageModule.forRoot({
    prefix: 'InTime',
    separator: '.',
    caseSensitive: true
  })));

  providers.push(importProvidersFrom(MetaModule.forRoot()));

  providers.push(provideFirebaseApp(() => initializeApp(environment.firebase)));
  providers.push(provideFirestore(() => {
    const firestore = initializeFirestore(getApp(), {
      experimentalForceLongPolling: true,
      experimentalAutoDetectLongPolling: false,
    });

    return firestore;
  }));
  providers.push(provideFunctions(() => getFunctions(undefined, 'europe-west1')));
  providers.push(provideAnalytics(() => getAnalytics()));
  providers.push(provideStorage(() => getStorage()));
  providers.push(provideAuth(() => getAuth()));
  providers.push(provideBrowserAppInitializer());

  return providers;
}

export const appConfig: ApplicationConfig = {
  providers: getAppProviders()
};
