import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './app/app.environment';
import { isSentryAllowed } from './app/app.sentry';
import * as Sentry from '@sentry/angular-ivy';

if(environment.production) {
  enableProdMode();
}

Sentry.init({ dsn: environment.sentryDsn, enabled: isSentryAllowed(), tracesSampleRate: 1.0 });

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
