import dayjs from 'dayjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { Country } from 'in-time-core';
import { Subscription } from 'rxjs';
import { Locale } from '../../../core/models/locale';
import { environment } from '../../../app.environment';
import { GeoLocationService, LanguageService, LayoutService } from '../../../services';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatMenuModule,
  ],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.scss'
  ]
})
export class FooterComponent implements OnInit, OnDestroy {
  private readonly languageService = inject(LanguageService);
  private readonly geoLocationService = inject(GeoLocationService);
  private readonly layoutService = inject(LayoutService);
  private readonly _document = inject(DOCUMENT);

  private subscriptions: Subscription[] = [];

  public readonly currentLocale = signal(LanguageService.LOCALES[environment.defaultLanguage] as Locale);
  public readonly showBarionLogo = signal(false);
  public readonly showAnpcLogo = signal(false);
  public readonly showFooter = signal(true);
  public readonly currentYear: string;
  public readonly locales: Locale[];

  constructor() {
    this.currentYear = dayjs().format('YYYY');
    this.locales = this.languageService.supportedLocales;
  }

  changeLocale(locale: Locale): void {
    this.languageService.setLocale(locale);
  }

  ngOnInit(): void {
    const hostname = this._document.location?.hostname;
    const tld = hostname != null ? hostname.split('.')[1] : null;

    this.currentLocale.set(this.languageService.locale);
    this.showFooter.set(this.layoutService.state.showFooter);
    this.showBarionLogo.set(this.geoLocationService.country === Country.Hungary);
    this.showAnpcLogo.set(tld?.toLowerCase() == 'ro' || this.geoLocationService.country === Country.Hungary);

    this.subscriptions.push(this.languageService.locale$.subscribe(
      (locale) => this.currentLocale.set(locale)
    ));

    this.subscriptions.push(this.layoutService.state$.subscribe(
      (state) => this.showFooter.set(state.showFooter)
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.length = 0;
  }
}