import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { EmptyClass, isNullOrUndefined } from 'in-time-core';
import { LocalStorageService } from 'ngx-webstorage';
import { tryToFindUserAgentVerbose } from './core/utils/web-utils';
import { AnalyticsService, AuthenticationService, DatabaseService, UtmParamsService } from './services';
import { STORAGE_USER_AGENT } from './core/utils/storage-keys';
import { CookiesPanelComponent, FooterComponent, HeaderComponent, NavbarComponent } from './ui/components';
import { MetaMixin } from './core/mixins/meta-mixin';
import { isPlatformBrowser } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { registerCustomIcons } from './core/utils/icon-utils';

@Component({
  imports: [
    RouterOutlet,
    TranslocoModule,
    CookiesPanelComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
  ],
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends MetaMixin(EmptyClass) implements OnInit {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly databaseService = inject(DatabaseService);
  private readonly utmParamsService = inject(UtmParamsService);
  private readonly localStorageService = inject(LocalStorageService, { optional: true });
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    super();
    registerCustomIcons(iconRegistry, domSanitizer);
  }

  ngOnInit(): void {
    this.setGenericMetaTags();
    this.logUserAgentIfNecessary();

    this.analyticsService.onInitTracking();
    this.utmParamsService.initialize(this.router);
    if(this.isBrowser) {
      this.authenticationService.checkIfSignedIn();
    }
  }

  private logUserAgentIfNecessary(): void {
    if(this.localStorageService == null) {
      return;
    }

    const hasEverSavedUserAgent = this.localStorageService.retrieve(STORAGE_USER_AGENT) === true;
    if(hasEverSavedUserAgent) {
      return;
    }

    const userAgent = tryToFindUserAgentVerbose();
    if(isNullOrUndefined(userAgent)) {
      return;
    }

    this.databaseService.logUserAgent(userAgent).then((response) => {
      if(response.success) {
        this.localStorageService?.store(STORAGE_USER_AGENT, true);
      }
    });
  }
}
