/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/angular-ivy';

import { isNullOrUndefinedOrEmpty } from 'in-time-core';
import { ChannelMask, LogLevel, Logger } from 'in-time-logger';
import { SENTRY_INFO_CHANNEL, SENTRY_NO_OP_CHANNEL } from './logging-utils';

export class SentryLogger extends Logger {
  constructor(channels: ChannelMask | null = null) {
    if(channels == null) {
      const map = new Map<string, boolean>();
      map.set(SENTRY_NO_OP_CHANNEL, false);
      map.set(SENTRY_INFO_CHANNEL, true);

      channels = new ChannelMask(map);
    }
    super(channels);
  }

  override get logLevel(): LogLevel {
    return LogLevel.Info;
  }

  override logDebug(message: string, args?: { channel?: string }): void {
    if(this.isChannelOn(args?.channel ?? SENTRY_NO_OP_CHANNEL)) {
      Sentry.captureMessage(message, 'debug');
    }
  }

  override logInfo(message: string, args?: { channel?: string }): void {
    if(this.isChannelOn(args?.channel ?? SENTRY_NO_OP_CHANNEL)) {
      Sentry.captureMessage(message, 'info');
    }
  }

  override logWarning(warning: string, args?: { channel?: string }): void {
    if(this.isChannelOn(args?.channel ?? SENTRY_NO_OP_CHANNEL)) {
      Sentry.captureMessage(warning, 'warning');
    }
  }

  override logError(error: string, args?: { channel?: string, stackTrace?: string }): void {
    if(this.isChannelOn(args?.channel)) {
      if(isNullOrUndefinedOrEmpty(args?.stackTrace)) {
        Sentry.captureMessage(error, 'error');
      }
      else {
        Sentry.captureMessage(`${error} ${args?.stackTrace}`, 'error');
      }
    }
  }

  override logException(exception: string, args?: { channel?: string, stackTrace?: string }): void {
    if(this.isChannelOn(args?.channel)) {
      if(isNullOrUndefinedOrEmpty(args?.stackTrace)) {
        Sentry.captureMessage(`${exception}`, 'error');
      }
      else {
        Sentry.captureMessage(`${exception} ${args?.stackTrace}`, 'error');
      }
    }
  }

  override logFatal(error: string, args?: { channel?: string, stackTrace?: string}): void {
    if(this.isChannelOn(args?.channel)) {
      if(isNullOrUndefinedOrEmpty(args?.stackTrace)) {
        Sentry.captureMessage(error, 'fatal');
      }
      else {
        Sentry.captureMessage(`${error} ${args?.stackTrace}`, 'fatal');
      }
    }
  }
}
