import {
  Component, ElementRef, OnDestroy, OnInit, Signal, ViewChild,
  WritableSignal, computed, inject, signal,
} from '@angular/core';

import {
  AccountService, AuthenticationService, LanguageService, LayoutService,
  SearchService, LayoutMode,
} from '../../../services';

import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { CustomerAccount } from 'in-time-core';
import { Locale } from '../../../core/models/locale';
import { environment } from '../../../app.environment';
import { DialogService } from '../../../core/components';
import { showSignInDialog } from '../../../auth/auth.dialog-utils';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    MatMenuModule,
  ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('mobileSearchInput', { static: false })
    mobileSearchInputRef: ElementRef | null = null;

  private readonly dialogService = inject(DialogService);
  private readonly authService = inject(AuthenticationService);
  private readonly accountService = inject(AccountService);
  private readonly languageService = inject(LanguageService);
  private readonly layoutService = inject(LayoutService);
  private readonly searchService = inject(SearchService);
  private readonly router = inject(Router);

  private readonly loginRouteSnapshots: BehaviorSubject<boolean>;
  private readonly subscriptions: Subscription[] = [];

  public readonly account: WritableSignal<CustomerAccount | null> = signal(null);
  public readonly hasAccount: Signal<boolean> = computed(() => this.account()  != null);

  public readonly currentLocale = signal(LanguageService.LOCALES[environment.defaultLanguage] as Locale);
  public readonly searchMode = signal(LayoutMode.MobileAndDesktop);
  public readonly headerMode = signal(LayoutMode.DesktopOnly);
  public readonly isSearchActive = signal(false);
  public readonly isSignInAllowed = signal(false);
  public readonly query = signal('');
  public readonly customStyle = signal('');
  public readonly locales: Locale[];

  public readonly showHeaderOnDesktop = computed(() => {
    return this.headerMode() == LayoutMode.DesktopOnly || this.headerMode() == LayoutMode.MobileAndDesktop;
  });

  public readonly showHeaderOnMobile = computed(() => {
    return this.headerMode() == LayoutMode.MobileOnly || this.headerMode() == LayoutMode.MobileAndDesktop;
  });

  public readonly showSearchOnDesktop = computed(() => {
    return this.searchMode() == LayoutMode.DesktopOnly || this.searchMode() == LayoutMode.MobileAndDesktop;
  });

  public readonly showSearchOnMobile = computed(() => {
    return this.searchMode() == LayoutMode.MobileOnly || this.searchMode() == LayoutMode.MobileAndDesktop;
  });

  constructor() {
    this.loginRouteSnapshots = new BehaviorSubject<boolean>(false);
    this.locales = this.languageService.supportedLocales;
    this.currentLocale.set(this.languageService.locale);
    this.account.set(this.accountService.account);
    this.query.set(this.searchService.handler?.query ?? '');
    this.headerMode.set(this.layoutService.state.headerMode);
    this.searchMode.set(this.searchService.handler != null ? this.layoutService.state.searchMode : LayoutMode.None);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.router.events.subscribe((evt) => {
      if(evt instanceof NavigationEnd) {
        this.loginRouteSnapshots.next(evt.url.startsWith('/login'));
      }
    }));

    this.subscriptions.push(combineLatest([
      this.loginRouteSnapshots,
      this.layoutService.state$,
    ]).subscribe(([isLoginRoute, layout]) => {
      this.isSignInAllowed.set(isLoginRoute ? false : layout.allowSignIn);
    }));

    this.subscriptions.push(this.searchService.reset$.subscribe(
      () => this.onQueryChanged('')
    ));

    this.subscriptions.push(this.searchService.handler$.subscribe((handler) => {
      if(handler != null && handler.query.length <= 0) {
        this.isSearchActive.set(false);
      }
    }));

    this.subscriptions.push(this.layoutService.state$.subscribe((state) => {
      this.headerMode.set(state.headerMode);
      this.customStyle.set(state.headerStyle ?? '');
    }));

    this.subscriptions.push(combineLatest([
      this.searchService.handler$,
      this.layoutService.state$,
    ]).subscribe(([handler, layout]) => {
      this.query.set(handler?.query ?? '');
      this.searchMode.set(handler != null ? layout.searchMode : LayoutMode.None);
    }));

    this.subscriptions.push(this.searchService.sync$.subscribe(
      (handler) => this.query.set(handler?.query ?? '')
    ));

    this.subscriptions.push(this.languageService.locale$.subscribe(
      (locale) => this.currentLocale.set(locale)
    ));

    this.subscriptions.push(this.accountService.account$.subscribe(
      (account) => this.account.set(account)
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.length = 0;
  }

  signIn(): void {
    showSignInDialog(this.dialogService, null);
  }

  signOut(): void {
    this.authService.signOut();
  }

  changeLocale(locale: Locale): void {
    this.languageService.setLocale(locale);
  }

  onQueryChanged(query: string) {
    this.query.set(query);
    this.searchService.search(query);
  }

  onToggleSearch(): void {
    const isSearchActive = !this.isSearchActive();
    if(isSearchActive) {
      this.mobileSearchInputRef?.nativeElement?.focus();
    }
    else {
      this.onQueryChanged('');
    }

    this.isSearchActive.set(isSearchActive);
  }
}