import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// HOW TO ADD MORE ICONS:
// Save the svg file in assets/icons and add the filename (without extension) to the list below.
const CUSTOM_ICONS: string[] = [
  'pencil',
  'facebook',
  'google',
  'logout',
  'directions',
  'refresh',
  'download',
  'account-circle-outline',
  'home-outline',
  'cart-outline',
  'map-marker-outline',
  'ticket',
  'ticket-percent',
  'heart',
  'heart-outline',
  'share-variant',
  'share-variant-outline',
  'chevron-left',
  'chevron-right',
];

export function registerCustomIcons(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer): void {
  for(let i = 0; i < CUSTOM_ICONS.length; i++) {
    iconRegistry.addSvgIcon(
      CUSTOM_ICONS[i],
      domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${CUSTOM_ICONS[i]}.svg`)
    );
  }
}